import React, { useState } from "react";
import { Link, StaticQuery, graphql } from "gatsby";
import styled from "styled-components";
import { colors, breakpoints, fonts } from "../style-utilities/variables";
import ReactHtmlParser from "react-html-parser";
// import StickyBox from "react-sticky-box";

// IMAGES
import MobileVideo from '../images/video-horizontal.png';

//SECTIONS
import SEO from "../components/SEO";
import ImageCircle from "../components/ImageCircle";
import DefaultSharingImage from "./../static/ansira-logo-sharing.png";
import ContactBanner from '../components/ContactBanner';
import ButtonGrey from "../components/ButtonGrey";
import ModalVideo from 'react-modal-video';

const StyledContact = styled.main`
  margin: 2rem auto;
  max-width: 1500px;
  .blog-item {
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    padding: 0;
    border: 2px solid ${colors.secondaryGray};
  }
  .image-box {
    background-color: ${colors.white};
    width: 100%;
    overflow: hidden; /* need overflow: hidden for ie  */
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    margin-bottom: 0;
    img {
      margin-bottom: 0;
    }
  }
  .post-excerpt {
    flex: 1 1 auto; /* must use this shorthand for IE */
  }
  .post-link {
    height: auto;
  }
  .centered {
    text-align: center;
  }
  .hero-container h3 {
    color: #ec2d35;
    line-height: 35px;
    font-size: 35px;
    font-weight:400;
  }
  .hero-container h1 {
    color: #000;
    font-size: 125px;
    font-weight:700;
    line-height: 100px;
  }
  h1 {
    font-size:50px;
    line-height:45px;
  }
  .img-section {
    padding: 0px 0 40px;
  }
  .img-section:hover {
      cursor: pointer;
  }
  .post-details {
    flex-grow: 1;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    padding: 1em;
    background-color: ${colors.white};
  }
  .blog-item ul {
    margin:0;
  }
  .blog-item li {
    padding:1rem;
    border-bottom: 2px solid ${colors.secondaryGray};
  }
  .blog-item ul h4 {
    font-size: 20px;
    line-height: 20px;
    font-weight: 400;
  }
  .blog-item ul a {
    display:block;
    text-align: center;
  }
  .column-section {
    background-color: ${colors.secondaryGray};
    padding-top: 4rem;
    padding-bottom: 4rem;
  }
  .column-header h3, .column-header p {
    color: #FFF;
    font-weight:400;
    font-size:20px;
  }
  .column-header p {
    padding:0;
    margin:0;
  }
  .column-header {
    text-align: center;
    padding: 15px 0 15px 0;
    position: relative;
    height: 100%;
    max-height: 150px;
    overflow: hidden;
  }
  .column-sub-header {
    text-align:center;
    color: #757575;
    padding: 2rem 0.5rem 0 0.5rem;
    min-height:175px;
  }
  .column-red-title {
    font-weight:400px;
    line-height:20px;
    font-size:20px;
    color:#ec2d35;
    border-bottom: 3px solid #ec2d35;
    padding-bottom: 3px;
    font-weight:400;
  }
  .column-red-title h3:first-letter {
    text-transform:capitalize;
  }
  .column-red-title h3 {
    color:#ec2d35;
    font-size:20px;
    line-height:20px;
    padding:0;
    margin:0;
    text-transform:lowercase;
  }
  .column-list {
    background: #FFF;
    color: #757575;
  }
  .column-list ul {
    padding: 1rem 0.9rem 0 0;
  }
  .column-list ul li {
    background: url(https://info.ansira.com/hubfs/Ansira%20LP%20Template%20Images/Specific%20Page%20Images/COVID%20Recovery%20-%20Apr%202020/checkmark.png) no-repeat left top 7px transparent;
    padding-left: 25px;
  }
  .column-header strong {
    position: absolute;
    left: -10px;
    top: 0;
    position: ;
    font-size: 100px;
    line-height: 70px;
    color:#FFF;
  }
  .column-header h3 {
    margin:0 3rem 0 4rem;
  }
  .card-body {
    padding:0;
  }

  .img-section.left {
    img.desktop {
      display: none;
    }
  }

  @keyframes modal-video {
    from {
      opacity: 0;
    }

    to {
      opacity: 1;
    }
  }

  @keyframes modal-video-inner {
    from {
      transform: translate(0, 100px);
    }

    to {
      transform: translate(0, 0);
    }
  }

  .modal-video {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 1000000;
    cursor: pointer;
    opacity: 1;
    animation-timing-function: ease-out;
    animation-duration: 0.3s;
    animation-name: modal-video;
    -webkit-transition: opacity 0.3s ease-out;
    -moz-transition: opacity 0.3s ease-out;
    -ms-transition: opacity 0.3s ease-out;
    -o-transition: opacity 0.3s ease-out;
    transition: opacity 0.3s ease-out;
  }

  .modal-video-effect-exit {
    opacity: 0;

    & .modal-video-movie-wrap {
      -webkit-transform: translate(0, 100px);
      -moz-transform: translate(0, 100px);
      -ms-transform: translate(0, 100px);
      -o-transform: translate(0, 100px);
      transform: translate(0, 100px);
    }
  }

  .modal-video-body {
    max-width: 940px;
    width: 100%;
    height: 100%;
    margin: 0 auto;
    display: table;
  }

  .modal-video-inner {
    display: table-cell;
    vertical-align: middle;
    width: 100%;
    height: 100%;
  }

  .modal-video-movie-wrap {
    width: 100%;
    height: 0;
    position: relative;
    padding-bottom: 56.25%;
    background-color: #333;
    animation-timing-function: ease-out;
    animation-duration: 0.3s;
    animation-name: modal-video-inner;
    -webkit-transform: translate(0, 0);
    -moz-transform: translate(0, 0);
    -ms-transform: translate(0, 0);
    -o-transform: translate(0, 0);
    transform: translate(0, 0);
    -webkit-transition: -webkit-transform 0.3s ease-out;
    -moz-transition: -moz-transform 0.3s ease-out;
    -ms-transition: -ms-transform 0.3s ease-out;
    -o-transition: -o-transform 0.3s ease-out;
    transition: transform 0.3s ease-out;

    & iframe {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }

  .modal-video-close-btn {
    position: absolute;
    z-index: 2;
    top: -35px;
    right: 0;
    display: inline-block;
    width: 35px;
    height: 35px;
    overflow: hidden;
    border: none;
    background: transparent;
    @media (min-width: 1000px) {
      right: -35px;
    }
    &:before {
      transform: rotate(45deg);
    }

    &:after {
      transform: rotate(-45deg);
    }

    &:before,
    &:after {
      content: '';
      position: absolute;
      height: 2px;
      width: 100%;
      top: 50%;
      left: 0;
      margin-top: -1px;
      background: #fff;
      border-radius: 5px;
      margin-top: -6px;
    }
  }
`;

const Landing = ({ data }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const landing_page = data.wordpressPage;
  let additionalItemsArray = [];
  let columnOneListArray = [];
  let columnTwoListArray = [];
  let columnThreeListArray = [];
  let columnFourListArray = [];

  const additionalItems = landing_page.acf.additional_item_title;
  const columnOneItems = landing_page.acf.column_one_list_items;
  const columnTwoItems = landing_page.acf.column_two_list_items;
  const columnThreeItems = landing_page.acf.column_three_list_items;
  const columnFourItems = landing_page.acf.column_four_list_items;

  additionalItems.forEach(item => {
    additionalItemsArray.push({
      title: item.item_title,
      cta: item.item_cta,
      ctaURL: item.item_cta_url
    });
  });

  columnOneItems.forEach(item => {
    columnOneListArray.push({
      listItem: item.list_item
    });
  });

  columnTwoItems.forEach(item => {
    columnTwoListArray.push({
      listItem: item.list_item
    });
  });

  columnThreeItems.forEach(item => {
    columnThreeListArray.push({
      listItem: item.list_item
    });
  });

  columnFourItems.forEach(item => {
    columnFourListArray.push({
      listItem: item.list_item
    });
  });

  const openModal = () => {
    setIsModalOpen(true);
  }

  const closeModal = () => {
    setIsModalOpen(false);
  }

  return (
    <StyledContact className="animate">
      <SEO
        isBlogPost={false}
        postData={data.wordpressPage}
        postImage={DefaultSharingImage}
      />

      <div className="site-content">
        <section className="hero-container page-container">
          <div className="row">
            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
                <h3>{ReactHtmlParser(landing_page.acf.landing_small_title)}</h3>
                <h1>{ReactHtmlParser(landing_page.acf.landing_large_title)}</h1>
                {ReactHtmlParser(landing_page.acf.landing_intro_copy)}
            </div>
            <div className="col-md-6 img-section right">
              <div onClick={openModal}>
                <div className="d-none d-lg-block">
                    <img src={landing_page.acf.landing_intro_image.source_url} alt="Play Video" />
                </div>
                <div className="d-lg-none pl-3 pr-3">
                    <img className="mobile" src={MobileVideo} />
                </div>
            </div>

            <ModalVideo
                channel="vimeo"
                autoplay="true"
                isOpen={isModalOpen}
                videoId="434496385"
                onClose={closeModal}
              />
            </div>
          </div>
        </section>

        <div className="row">
          <div className="col-12">
            <h1 className="centered">{ReactHtmlParser(landing_page.acf.landing_article_main_headline)}</h1>
          </div>
        </div>

        <div className="post-area page-container">
          <div className="posts-display row">
            <div className="blog-item col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
              <React.Fragment>
                <div className="image-box">
                  <img src={landing_page.acf.landing_article_image.source_url} alt="Article one image" />
                </div>
                <div className="post-details">
                  <h4>
                    <Link to={ReactHtmlParser(landing_page.acf.landing_article_cta_url)} className="post-title">
                      {ReactHtmlParser(landing_page.acf.landing_article_title)}
                    </Link>
                  </h4>
                  <div className="post-excerpt">
                    {ReactHtmlParser(landing_page.acf.landing_article_copy)}
                  </div>
                  <div className="post-link">
                    <ButtonGrey
                      textLabel={ReactHtmlParser(landing_page.acf.landing_article_cta)}
                      pageLink={ReactHtmlParser(landing_page.acf.landing_article_cta_url)}
                      className="whiteCTA"
                    />
                  </div>
                </div>
              </React.Fragment>
            </div>

            <div className="blog-item col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
              <React.Fragment>
                <div className="image-box">
                  <img src={landing_page.acf.landing_article_image_two.source_url} alt="Article one image" />
                </div>
                <div className="post-details">
                  <h4>
                    <Link to={ReactHtmlParser(landing_page.acf.landing_article_cta_url_two)} className="post-title">
                      {ReactHtmlParser(landing_page.acf.landing_article_title_two)}
                    </Link>
                  </h4>
                  <div className="post-excerpt">
                    {ReactHtmlParser(landing_page.acf.landing_article_copy_two)}
                  </div>
                  <div className="post-link">
                    <ButtonGrey
                      textLabel={ReactHtmlParser(landing_page.acf.landing_article_cta_two)}
                      pageLink={ReactHtmlParser(landing_page.acf.landing_article_cta_url_two)}
                      className="whiteCTA"
                    />
                  </div>
                </div>
              </React.Fragment>
              </div>

              <div className="blog-item col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
                <ul className="list-unstyled">
                  {additionalItemsArray.map((item) => {
                    return <li className="list-item"><h4>{item.title}</h4><ButtonGrey textLabel={item.cta} externalLink={item.ctaURL} className="whiteCTA" /></li>;
                  })}
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="column-section mt-5">
          <div className="row mt-4">
            <div className="page-container">
              <div className="header">
                <div className="col">
                  <h1 className="centered">{ReactHtmlParser(landing_page.acf.column_section_main_header)}</h1>
                </div>
              </div>

              <div className="row">
                <div class="col-sm-12 col-md-6 col-lg-6 col-xl-3 d-flex">
                  <div class="card card-body flex-fill d-block">
                    <div className="column-header" style={{backgroundColor: '#000'}}>
                      <strong>1</strong>
                      <h3>{ReactHtmlParser(landing_page.acf.column_one_header)}</h3>
                    </div>
                    <div className="column-sub-header">
                        {ReactHtmlParser(landing_page.acf.column_one_sub_header)}
                      </div>
                      {landing_page.acf.column_one_red_title ? (
                        <div className="column-red-title" style={{marginLeft: '20px'}}>
                          <h3>{ReactHtmlParser(landing_page.acf.column_one_red_title)}</h3>
                        </div>
                      ) : (
                        <div className="column-red-title">&nbsp;</div>
                      )}
                      <div className="column-list">
                        <ul className="list-unstyled">
                          {columnOneListArray.map((item) => {
                            return <li className="list-item">{item.listItem}</li>;
                          })}
                        </ul>
                      </div>
                  </div>
                </div>

                <div class="col-sm-12 col-md-6 col-lg-6 col-xl-3 d-flex">
                  <div class="card card-body flex-fill d-block">
                    <div className="column-header" style={{backgroundColor: '#ec2d35'}}>
                      <strong>2</strong>
                      <h3>{ReactHtmlParser(landing_page.acf.column_two_header)}</h3>
                    </div>
                    <div className="column-sub-header">
                        {ReactHtmlParser(landing_page.acf.column_two_sub_header)}
                      </div>
                      {landing_page.acf.column_two_red_title ? (
                        <div className="column-red-title" style={{marginLeft: '20px'}}>
                          <h3>{ReactHtmlParser(landing_page.acf.column_two_red_title)}</h3>
                        </div>
                      ) : (
                        <div className="column-red-title">&nbsp;</div>
                      )}
                      <div className="column-list">
                        <ul className="list-unstyled">
                          {columnTwoListArray.map((item) => {
                            return <li className="list-item">{item.listItem}</li>;
                          })}
                        </ul>
                      </div>
                  </div>
                </div>

                <div class="col-sm-12 col-md-6 col-lg-6 col-xl-3 d-flex">
                  <div class="card card-body flex-fill d-block">
                    <div className="column-header" style={{backgroundColor: '#7bbac6'}}>
                      <strong>3</strong>
                      <h3>{ReactHtmlParser(landing_page.acf.column_three_header)}</h3>
                    </div>
                    <div className="column-sub-header">
                        {ReactHtmlParser(landing_page.acf.column_three_sub_header)}
                      </div>
                      {landing_page.acf.column_three_red_title ? (
                        <div className="column-red-title" style={{marginLeft: '20px'}}>
                          <h3>{ReactHtmlParser(landing_page.acf.column_three_red_title)}</h3>
                        </div>
                      ) : (
                        <div className="column-red-title">&nbsp;</div>
                      )}
                      <div className="column-list">
                        <ul className="list-unstyled">
                          {columnThreeListArray.map((item) => {
                            return <li className="list-item">{item.listItem}</li>;
                          })}
                        </ul>
                      </div>
                  </div>
                </div>

                <div class="col-sm-12 col-md-6 col-lg-6 col-xl-3 d-flex">
                  <div class="card card-body flex-fill d-block">
                    <div className="column-header" style={{backgroundColor: '#6d706e'}}>
                      <strong>4</strong>
                      <h3>{ReactHtmlParser(landing_page.acf.column_four_header)}</h3>
                    </div>
                    <div className="column-sub-header">
                      {ReactHtmlParser(landing_page.acf.column_four_sub_header)}
                    </div>
                    {landing_page.acf.column_four_red_title ? (
                      <div className="column-red-title" style={{marginLeft: '20px'}}>
                        <h3>{ReactHtmlParser(landing_page.acf.column_four_red_title)}</h3>
                      </div>
                    ) : (
                      <div className="column-red-title">&nbsp;</div>
                    )}
                    <div className="column-list">
                      <ul className="list-unstyled">
                        {columnFourListArray.map((item) => {
                          return <li className="list-item">{item.listItem}</li>;
                        })}
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <section className="centered">
          <ContactBanner
            headline={landing_page.acf.landing_contact_header}
            cta={landing_page.acf.landing_contact_cta}
            url={landing_page.acf.landing_contact_url}
          />
        </section>
    </StyledContact>
  );
};

export const query = graphql`
  query {
    wordpressPage(slug: { eq: "modernize-channel-partner-marketing-programs" }) {
      acf {
        seo_title
        seo_canonical
        seo_description
        landing_small_title
        landing_large_title
        landing_intro_copy
        landing_article_main_headline
        landing_article_title
        landing_article_title_two
        landing_article_copy
        landing_article_copy_two
        landing_article_cta
        landing_article_cta_two
        landing_article_cta_url
        landing_article_cta_url_two
        column_section_main_header
        column_one_header
        column_one_sub_header
        column_one_red_title
        column_one_list_items {
          list_item
        }
        column_two_header
        column_two_sub_header
        column_two_red_title
        column_two_list_items {
          list_item
        }
        column_three_header
        column_three_sub_header
        column_three_red_title
        column_three_list_items {
          list_item
        }
        column_four_header
        column_four_sub_header
        column_four_red_title
        column_four_list_items {
          list_item
        }
        additional_item_title {
          item_title
          item_cta
          item_cta_url
        }
        landing_article_image {
          title
          source_url
        }
        landing_article_image_two {
          title
          source_url
        }
        landing_intro_image {
          title
          source_url
        }
        landing_contact_header
        landing_contact_cta
        landing_contact_url
      }
      yoast_meta {
        yoast_wpseo_title
        yoast_wpseo_metadesc
        yoast_wpseo_canonical
      }
    }
  }
`;

export default Landing;
